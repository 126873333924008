import {curl_get, curl_post, requestSuccessAction} from "./http";
import {BASE_URL, ENDPOINTS} from "../constants";
import {gotoHome, gotoStep, gotoStripe, gotoSubscription, gotoPaymentSuccess} from "./router";
// Action Types
const types = {
    GET_SUBSCRIPTIONS_REQUEST: "GET_SUBSCRIPTIONS_REQUEST",
    GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
    GET_SUBSCRIPTIONS_FAILED: "GET_SUBSCRIPTIONS_FAILED",

    GET_SUBSCRIPTION_REQUEST: "GET_SUBSCRIPTION_REQUEST",
    GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
    GET_SUBSCRIPTION_FAILED: "GET_SUBSCRIPTION_FAILED",

    GET_USER_SUBSCRIPTION_REQUEST: "GET_USER_SUBSCRIPTION_REQUEST",
    GET_USER_SUBSCRIPTION: "GET_USER_SUBSCRIPTION",
    GET_USER_SUBSCRIPTION_FAILED: "GET_USER_SUBSCRIPTION_FAILED",

    STRIPE_REQUEST: "STRIPE_REQUEST",

    STRIPE_CHARGE_REQUEST: "STRIPE_CHARGE_REQUEST",
    STRIPE_CHARGE: "STRIPE_CHARGE",
    STRIPE_CHARGE_FAILED: "STRIPE_CHARGE_FAILED"
};
export default types;


export function getSubscriptions() {
    return (dispatch) => {
        dispatch({type: types.GET_SUBSCRIPTIONS_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_SUBSCRIPTIONS, {}, types.GET_SUBSCRIPTIONS_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_SUBSCRIPTIONS))
        })
    }
}

export function getSubscription(id) {
    return (dispatch) => {
        dispatch({type: types.GET_SUBSCRIPTION_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_SUBSCRIPTIONS + '/' + id, {}, types.GET_SUBSCRIPTION_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_SUBSCRIPTION))
        })
    }
}

export function getUserSubscription(token) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.GET_USER_SUBSCRIPTION_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.GET_USER_SUBSCRIPTION, {current: 1}, types.GET_USER_SUBSCRIPTION_FAILED, (data) => {
            dispatch(requestSuccessAction({data}, types.GET_USER_SUBSCRIPTION))
        }, config)
    }
}


export function stripe(membership_info) {
    return (dispatch) => {
        dispatch(requestSuccessAction({membership_info}, types.STRIPE_REQUEST))
        dispatch(gotoStripe());
    }
}

export function stripeCharge(token, payload) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.STRIPE_CHARGE_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.STRIPE_CHARGE, payload,
            types.STRIPE_CHARGE_FAILED, (data) => {
                dispatch(requestSuccessAction(data, types.STRIPE_CHARGE))
                setTimeout(function () {
                    dispatch(gotoSubscription());
                }, 3000);
            }, config);
    }
}

export function stripeChargeWithOutLogin(payload) {
    let config = {};
    return (dispatch) => {
        dispatch({type: types.STRIPE_CHARGE_REQUEST});
        curl_post(dispatch, BASE_URL + ENDPOINTS.STRIPE_CHARGE_WITHOUT_LOGIN, payload,
            types.STRIPE_CHARGE_FAILED, (data) => {
                console.log(data, "without login");
                // dispatch(requestSuccessAction(data, types.STRIPE_CHARGE))
                const phone = data.details.phone;
                console.log(phone, "phone");
                if(phone) {
                    localStorage.setItem('memberId', phone);
                    setTimeout(function () {
                        dispatch(gotoPaymentSuccess());
                    }, 500);
                }else {
                    setTimeout(function () {
                        dispatch(gotoPaymentSuccess());
                    }, 500);
                }
                
            }, config);
    }
}