import React, {Component, createRef} from 'react';
import connect from "react-redux/es/connect/connect";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {
    AUTHORIZE_NET_ENV,
    AUTHORIZE_NET_LOGIN_ID,
    AUTHORIZE_NET_PUBLIC_KEY,
    GENDER_FEMALE,
    GENDER_MALE, GENDER_TEXT, RELATIONSHIP_TEXT,
    BASE_URL, ENDPOINTS
} from "../constants";
import DatePicker from "react-datepicker";
import {FormContainer, FormComponent} from "react-authorize-net";
import {toast} from "react-toastify";
import {stripeCharge, stripeChargeWithOutLogin} from "../actions/subscription";
import Spinner from "react-spinners/FadeLoader";
import AddSubMember from "./AddSubMember";

class PaymentInfo extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.alertRef = createRef();
        this.state = {
            toast: false,
            status: "paid" | "unpaid" | "failure",
            sub_members: [],
            showModal: false
        }
        this.state = {
            first_name: this.props.user.loggedIn ? this.props.user.details.first_name : '',
            last_name: this.props.user.loggedIn ? this.props.user.details.last_name : '',
            work_phone: this.props.user.loggedIn ? this.props.user.details.phone : '',
            home_phone: this.props.user.loggedIn ? this.props.user.details.phone : '',
            email: this.props.user.loggedIn ? this.props.user.email : '',
            dob: this.props.user.loggedIn && this.props.user.details.dob ? new Date(this.props.user.details.dob) : null,
            gender: this.props.user.loggedIn ? this.props.user.details.gender : GENDER_MALE,
            address: this.props.user.loggedIn ? this.props.user.details.address : '',
            city: this.props.user.loggedIn ? this.props.user.details.city : '',
            state: this.props.user.loggedIn ? this.props.user.details.state : '',
            zipcode: this.props.user.loggedIn ? this.props.user.details.zipcode : '',
            billing_same: true,
            serverError: false,
            serverErrorMessage: '',
            states: []
        }
        this.addMember = this.addMember.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const hasReloaded = urlParams.get('membership');
        if (!hasReloaded) {
        urlParams.set('membership', 'true');
        window.location.search = urlParams.toString();
        } else {
        const urlWithoutReload = window.location.href.split('?')[0];
        window.history.replaceState({}, document.title, urlWithoutReload);
        }
        axios.get(BASE_URL + ENDPOINTS.GET_STATES).then((response) => {
            let data = response.data;
            let updatedStates = data.data.map(state => {
                return {
                    ...state,
                    twoCode: state["2_code"]
                };
            });
            this.setState({ states: updatedStates });
        })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.stripeChargeSuccess && nextProps.stripeChargeSuccess) {
            toast(nextProps.successMsg)
            this.setState({toast: true})
        } else if (!this.props.error && nextProps.error) {
            this.setState({serverError: true, serverErrorMessage: nextProps.error.message})
            this.scrollToAlert();
            console.log('nextProps.error.message 1', nextProps.error.message);
           // toast.error(nextProps.error.message)
            this.setState({toast: true})
        }
    }
    closeAlert = () => {
        this.setState({ serverError: false });
    };

    scrollToAlert = () => {
        console.log('this.alertRef', this.alertRef);
        if (this.alertRef.current) {
            this.alertRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    handleAge = date => {
        this.setState({
            dob: date
        });
    };

    handleChangeText(key, txt) {
        this.setState({[key]: txt});
    }
    onErrorHandler = (response) => {
        console.log('error payment validation');
        
        this.setState({serverError: true, serverErrorMessage: response.messages.message.map(err => err.text)[0]})
        this.scrollToAlert();
       // toast.error(response.messages.message.map(err => err.text)[0])
        this.setState({
            status: ["failure", response.messages.message.map(err => err.text)]
        });
    };

    onSuccessHandler = (response) => {
        let payload = {
            opaqueData: response.opaqueData,
            membership_info: JSON.stringify(this.props.membership_info),
            payment_info: JSON.stringify(this.state),
            sub_members: JSON.stringify(this.state.sub_members)
        }
        let form = document.getElementById("payment-form");
        // Process API response on your backend...
        if (form.checkValidity()) {
            if (this.props.user.loggedIn) {
                this.props.stripeCharge(this.props.user.access.token, payload)
            } else {
                this.props.stripeChargeWithOutLogin(payload)
            }
            this.setState({toast: false})
        }
    };


    handleSubmit(e) {
        e.preventDefault();
        //   console.log('checking....')
    }

    handleClose() {
        this.setState({showModal: false})
    }

    async handleRemove(index) {
        let members = this.state.sub_members || []
        let new_members = []
        for (let i = 0; i < members.length; i++) {
            if (i != index) {
                new_members.push(members[i])
            } else {
                continue;
            }
        }
        this.setState({sub_members: new_members})
    }

    handleOpenModal() {
        this.setState({showModal: true})
    }

    addMember(e) {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries());
        let sub_members = this.state.sub_members ? this.state.sub_members : []
        sub_members.push(data)
        this.setState({sub_members: sub_members, showModal: false})
    }

    render() {
        let user = this.props.user;
        let sub_members = this.state.sub_members ? this.state.sub_members : []
        const ExampleCustomInput = ({value, onClick}) => (
            <input type="text" className="def example-custom-input" onClick={onClick} value={value} readOnly/>
        );
        let subMemberElement = sub_members.map((member, index) => {
            return (
                <tr key={index}>
                    <td>{member.name}</td>
                    <td>{RELATIONSHIP_TEXT[member.relationship]}</td>
                    <td onClick={() => {
                        this.handleRemove(index)
                    }}><i className="fas fa-trash-alt" style={{color: "red"}}></i>
                    </td>
                </tr>
            )
        })
        let today_date = new Date()
        let month = today_date.getMonth() + 1
        month = month > 9 ? month : "0" + month
        let day = today_date.getDate()
        day = day > 9 ? day : "0" + day
        today_date = today_date.getFullYear() + "-" + month + "-" + day
        return (
            <section className="sec-payment">
                <div className="container" ref={this.alertRef}>
                    {this.state.serverError && (
                    <div  className="alert alert-danger alert-dismissible fade show" role="alert">
                        {this.state.serverErrorMessage}
                        <button type="button" className="close" onClick={this.closeAlert} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    )}
                       
                    <form id="payment-form" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Personal Information</h4>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>First Name*</label>
                                        <input className="def" type="text" value={this.state.first_name} name=""
                                               placeholder="First Name" onChange={(e) => {
                                            this.handleChangeText('first_name', e.target.value)
                                        }} required/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Last Name*</label>
                                        <input className="def" type="text" onChange={(e) => {
                                            this.handleChangeText('last_name', e.target.value)
                                        }} value={this.state.last_name} name=""
                                               placeholder="Last Name" required/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Middle Initial:</label>
                                        <input className="def" type="text" onChange={(e) => {
                                            this.handleChangeText('middle_initial', e.target.value)
                                        }} name=""/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Title:</label>
                                        <input className="def" type="text" name="" onChange={(e) => {
                                            this.handleChangeText('title', e.target.value)
                                        }} placeholder="Title"/>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <label>Gender*</label>
                                        <select className="def" value={this.state.gender} onChange={(e) => {
                                            this.handleChangeText('gender', e.target.value)
                                        }} required>
                                            <option value={GENDER_MALE}>Male</option>
                                            <option value={GENDER_FEMALE}>Female</option>
                                        </select>
                                    </div> */}
                                    {/* <div className="col-md-12">
                                        <label>Date of Birth: *</label>
                                        <input type="date" maxLength="50" className="def"
                                               id="exampleFormControlInputDob" name="dob" value={this.state.dob}
                                               max={today_date} onChange={(e) => {
                                            this.handleChangeText('dob', e.target.value)
                                        }} required/>
                                    </div> */}
                                    <div className="col-md-6">
                                        <label>Cell Phone: *</label>
                                        <input type="tel" className="def" value={this.state.work_phone}
                                               onChange={(e) => {
                                                   this.handleChangeText('work_phone', e.target.value)
                                               }} required
                                               onInput="this.value=this.value.replace(/[^0-9]/g,'');" maxLength="11"
                                               minLength="10" placeholder="(xxx) xxx-xxxx"/>

                                    </div>
                                    <div className="col-md-6">
                                        <label>Home Phone: *</label>

                                        <input type="tel" name="phone" className="def" value={this.state.home_phone}
                                               onChange={(e) => {
                                                   this.handleChangeText('home_phone', e.target.value)
                                               }} required
                                               onInput="this.value=this.value.replace(/[^0-9]/g,'');" maxLength="11"
                                               minLength="10" placeholder="(xxx) xxx-xxxx"/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Email Address: </label>
                                        <input className="def" value={this.state.email} type="email" onChange={(e) => {
                                            this.handleChangeText('email', e.target.value)
                                        }}
                                               placeholder="your@emailaddress.com"/>
                                    </div>
                                    <div className="col-md-12 mrgn-top">
                                        <h5>Your Address:</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Address: *</label>
                                        <input className="def" type="text" value={this.state.address} name=""
                                               placeholder="Address" onChange={(e) => {
                                            this.handleChangeText('address', e.target.value)
                                        }} required/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>City: *</label>
                                        <input className="def" value={this.state.city} type="text" name=""
                                               placeholder="City" onChange={(e) => {
                                            this.handleChangeText('city', e.target.value)
                                        }} required/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>State: *</label>
                                        <select class="def" onChange={(e) => {
                                            this.handleChangeText('state', e.target.value)
                                        }} required>
                                        <option disabled selected>Select a State</option>
                                        {this.state.states.map( (state, key) => {
                                            return <option value={state.twoCode} key={key}>{state.name}</option>
                                        }  )}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>ZIP Code: *</label>
                                        <input className="def" type="text" name="" value={this.state.zipcode}
                                               placeholder="ZIP Code" onChange={(e) => {
                                            this.handleChangeText('zipcode', e.target.value)
                                        }} required/>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-5">
                                        <h5>Billing Information</h5>
                                    </div>
                                    <div className="col-md-7 text-right">
                                        <input type="checkbox" id="billing" name="billing" value=""
                                               onClick={() => {
                                                   if (this.state.billing_same) {
                                                       this.setState({billing_same: false});
                                                   } else {
                                                       this.setState({billing_same: true});
                                                   }
                                               }}/>
                                        <label htmlFor="billing" className="sm"> Same as Personal
                                            Information.</label>
                                    </div>
                                </div>
                                <div
                                    className={this.state.billing_same ? "row billing-area" : "row billing-area hide"}>
                                    <div className="col-md-6">
                                        <label>Address: *</label>
                                        {this.state.billing_same ?
                                            <input className="def" type="text" name="" placeholder="Address"
                                                   onChange={(e) => {
                                                       this.handleChangeText('billing_address', e.target.value)
                                                   }} required/> :
                                            <input className="def" type="text" name="" placeholder="Address"
                                                   onChange={(e) => {
                                                       this.handleChangeText('billing_address', e.target.value)
                                                   }}/>}
                                    </div>
                                    <div className="col-md-6">
                                        <label>City: *</label>
                                        {this.state.billing_same ?
                                            <input className="def" type="text" name="" onChange={(e) => {
                                                this.handleChangeText('billing_city', e.target.value)
                                            }} placeholder="City" required/> :
                                            <input className="def" type="text" name="" onChange={(e) => {
                                                this.handleChangeText('billing_city', e.target.value)
                                            }} placeholder="City"/>}

                                    </div>
                           
                                    <div className="col-md-6">
                                        <label>State: *</label>
                                        {this.state.billing_same ?
                                            <select class="def" onChange={(e) => {
                                                this.handleChangeText('billing_state', e.target.value)
                                            }} required>
                                            <option disabled selected>Select a State</option>
                                            {this.state.states.map( (state, key) => {
                                                return <option value={state.twoCode} key={key}>{state.name}</option>
                                            }  )}
                                            </select> :
                                            <select class="def" onChange={(e) => {
                                                this.handleChangeText('billing_state', e.target.value)
                                            }} required>
                                            <option disabled selected>Select a State</option>
                                            {this.state.states.map( (state, key) => {
                                                return <option value={state.twoCode} key={key}>{state.name}</option>
                                            }  )}
                                            </select>}
                                    </div>
                                    <div className="col-md-6">
                                        <label>ZIP Code: *</label>
                                        {this.state.billing_same ?
                                            <input className="def" type="text" name="" onChange={(e) => {
                                                this.handleChangeText('billing_zipcode', e.target.value)
                                            }} placeholder="ZIP Code" required/> :
                                            <input className="def" type="text" name="" onChange={(e) => {
                                                this.handleChangeText('billing_zipcode', e.target.value)
                                            }} placeholder="ZIP Code"/>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>Add Sub Members</h5>
                                    </div>
                                </div>
                                {sub_members.length > 0 ? <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Relation</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {subMemberElement}
                                    </tbody>
                                </table> : null}
                                <AddSubMember addMember={this.addMember} showModal={this.state.showModal}
                                              handleClose={this.handleClose} handleOpenModal={this.handleOpenModal}/>
                                <div className="row payment mrgn-top">
                                    <div className="col-md-12">
                                        <h5>Payment Information</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="pad-15" src={require("../../images/accepted-cards.png")}
                                             style={{width: "100%", height: "auto"}}/>
                                    </div>
                                    <div className="col-md-6 text-right">

                                        <a href="https://verify.authorize.net/anetseal/?pid=20d90fd5-4ab0-4069-a95b-215858a7bb96&amp;rurl=https://www.mainlinedentalplan.com/register/"
                                           rel="noopener noreferrer" target="_blank">
                                            <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
                                                 width="90"
                                                 height="72" border="0"
                                                 alt="Authorize.Net Merchant - Click to Verify"/>
                                        </a>
                                    </div>
                                    {this.props.stripeChargeRequest ?
                                        <div className="payment-form spinner-loader"><Spinner
                                            size={200}
                                            color={"#4A90E2"}
                                        /></div> :
                                        <FormContainer apiLoginId={AUTHORIZE_NET_LOGIN_ID}
                                                       environment={AUTHORIZE_NET_ENV}
                                                       clientKey={AUTHORIZE_NET_PUBLIC_KEY}
                                                       amount={this.props.membership_info.amount}
                                                       component={FormComponent} onError={this.onErrorHandler}
                                                       onSuccess={this.onSuccessHandler}/>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {
            user: state.user,
            stripeChargeSuccess: state.subscriptions.stripeChargeSuccess || false,
            stripeChargeRequest: state.subscriptions.stripeChargeRequest || false,
            successMsg: state.subscriptions.successMsg,
            error: state.subscriptions.error || false
        };
    },
    {stripeCharge, stripeChargeWithOutLogin}
)(PaymentInfo);