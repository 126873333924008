import React, { useEffect } from "react";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {getMember} from "../actions/user";
import Loader from "../components/Loader";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Alerts from "../components/Alerts";
import {toast, ToastContainer} from "react-toastify";
import {PACKAGE_STATUSES} from "../constants";

import { NumericFormat } from 'react-number-format';


const PaymentSuccess = ({ member, getMember }) => {
  useEffect(() => {
    const memberId = localStorage.getItem('memberId'); 
    if(memberId) {
      getMember(memberId);
      localStorage.removeItem('memberId');
    }
    
  }, [getMember]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="es-container es-bg-white">
            <div className="es-header">
              <h1>
                Thank you for subscribing to Mainline Dental Plan! <br /> You
                can start saving today!
              </h1>
            </div>

            <div className="es-body">
              <h3 className="es-text-center">User Information:</h3>
              <hr className="es-separator" />

              <ul className="es-list">
                <li>
                  <strong>Name :</strong> {member ? member.details.full_name : "N/A"}
                </li>
                <li>
                  <strong>Address :</strong> {member ? member.details.address : "N/A"}
                </li>
                <li>
                  <strong>Email :</strong> {member ? member.details.email : "N/A"}
                </li>
                <li>
                  <strong>Phone :</strong> {member ? member.details.phone : "N/A"}
                </li>
              </ul>
              <h5 className="es-note">
                Note: Your phone # can be used to verify your enrollment.
              </h5>

              <h3 className="es-text-center">Here are your plan details:</h3>
              <hr className="es-separator" />

              <ul className="es-list">
                <li>
                  <strong>Status :</strong> {member ? PACKAGE_STATUSES[member.package_status] : "N/A"}
                </li>
                <li>
                  <strong>Type :</strong> {member ? member.membership_type : "N/A"}
                </li>
                <li>
                  <strong>Amount :</strong> {member ? `$${member.amount}` : "N/A"}
                </li>
                <li>
                  <strong>Expiration :</strong> {member ? member.package_expire_at : "N/A"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.user.member,
});

const mapDispatchToProps = {
  getMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
